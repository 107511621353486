import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const CompressedImageComponent = ({ imageUrl, ...props }) => {
  return (
    <>
      <LazyLoadImage
        effect="blur"
        src={imageUrl}
        height="100%"
        {...props}
      />
    </>
  );
};

export default CompressedImageComponent;
