export const iceServers = {
  iceServers: [
    {
      urls: "stun:site.mybeedoo.com:5349",
    },
    {
      urls: "turn:site.mybeedoo.com:5349",
      username: "mybeedooVoip",
      credential: "Myb$$dOO2021!Coturn",
    },
    {
      urls: "stun:stun.services.mozilla.com",
    },
    {
      urls: "stun:stun.l.google.com:19302",
    },
  ],
};

export const streamConstraints = {
  video: {
    facingMode: "user",
  },
  audio: true,
};

export const tinyFaceDetectionParams = {
  // inputSize: 200
};

export const RabbitmqConfig = {
  url: 'wss://msg.mybeedoo.com:15673/ws', // PROD
  username: 'admin',
  password: 'jAQUBLoVo23GlQQSYDDjja$',
  vhost: '/web_production',

  // url: "wss://msg.mybeedoo.com:15673/ws", // PRE PROD
  // username: "dev-web",
  // password: "KphEXg9BxuinhVTqLNQT",
  // vhost: "/web_developpement",

  // url: 'ws://192.168.88.27:15674/ws', // PRE PROD
  // username: 'guest',
  // password: 'guest',
  // vhost: '/',
};

export const stompConfig = {
  url: 'https://ws.mybeedoo.com/ws', // PROD

  // url: "https://ws-dev.mybeedoo.com/ws", // PREPROD

  // url: 'http://192.168.88.27:8082/ws',

  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  reconnectDelay: 3000, // Délai de reconnexion en millisecondes
  // debug: true,
};

export const api = {
  baseUrl: "https://ws.mybeedoo.com/api/v1", // PROD
  stripePromise: 'pk_live_51Lh7J3Duq68iAg8NbVXtXlefShm67s7LiRrlKeHhrBTtlC5AzV9nYIwM6n1GlKD2aQZ806I6cXNbbcHZonwGfMBT00kIGhsW9n',
  PAYPAL_CLIENT_ID: 'ARHZteCf9_9W95ZYFgxk20qBAaJDK9Xu6zyJ2BucPVYj-0yaYOtToXm71r2cJPsL6CSOmjI57KdOwTgc',
  moderateur_id: '642c3671cff3ff4df1b5cbb6',

  // baseUrl: "https://ws-dev.mybeedoo.com/api/v1", // PREPROD
  // stripePromise: "pk_test_51Lh7J3Duq68iAg8N6ejhWWYblSd3x1C3bSOXtr7ebFPt6QoKSdUsRvRjWWfswtLniGdU4tHCft1WxhHfYmJTJqNB000HU5SEWr",
  // PAYPAL_CLIENT_ID: "AbdblwK7ARBjtTalsseBwLcQtL5sdjKBAWc3hSex4vx9mRK-e9nsgtOrEdjhkzm1bqymM05QsQ_hgiD6",
  // moderateur_id: "643eab1e367e10ae448d88b3",

  // baseUrl: 'http://192.168.88.27:8082/api/v1',
  // baseUrl: 'http://localhost:8080/api/v1',		// DEV LOC
  // stripePromise: 'pk_test_Zf0VURwyYAnoRtXBO0DPpEMJ',

  PAYPAL_CLIENT_ID_PREPROD: 'AbdblwK7ARBjtTalsseBwLcQtL5sdjKBAWc3hSex4vx9mRK-e9nsgtOrEdjhkzm1bqymM05QsQ_hgiD6',
  PAYPAL_CLIENT_ID_PROD: "ARHZteCf9_9W95ZYFgxk20qBAaJDK9Xu6zyJ2BucPVYj-0yaYOtToXm71r2cJPsL6CSOmjI57KdOwTgc",

  GOOGLE_TRANSLATE_API_KEY: "252721287992-ja5a00ehfcjiq93r1c22t1c11diijkb9.apps.googleusercontent.com",

  pays: "/utils/pays",
  requestValidationCodeByEmail: "/validation-codes",
  requestCheckValidationCode: "/validation-codes/check",
  inscriptions: "/inscriptions",
  resetpasswordWithCode: "/password-resets/finish",
  requestValidationCodeBySms: "/validation-codes/sms",
  resetPasswordInscription: "/password-resets",
  login: "/users/login",
  inscriptionFacebook: "/inscriptions/signup-fb",
  inscriptionGoogle: "/inscriptions/signup-google",
  loginFacebook: "/users/login-fb",
  loginGoogle: "/users/login-google",
  checkToken: "/users/check-token",
  inscriptionInstagram: "/inscriptions/signup-instagram",
  users: "/users",
  myUsersData: "/users/check-token",
  userByPseudo: "/public/users/pseudo",
  userById: "/public/users/",
  searchPublic: "/public/search",
  usersAttachments: "/users/attachments",
  usersForConnected: "/users/for-connected",
  requestFriend: "/users/friends",
  contents: "/contents",
  myContents: "/my-contents",
  liveId: "/live-id",
  hashtags: "/tags",
  getAllDiscussions: "/discussions",
  discussionByUserId: "/discussions/user",
  comments: "/comments",
  signalement: "/signalements",
  activities: "/recent-activities",
  myLikes: "/my-likes",
  contentsWithLikes: "/content-with-likes",
  paniers: "/paniers",
  share_view_contents: "/share-view-contents",
  orders: "/orders",
  orders_items: "/order-items",
  orders_paid: "/order-items",
  orders_itemsAccept: "/order-items/ordersId/accept",
  livraisons: "/adresse-livraisons",
  last_livraisons: "/last-adresse-livraison",
  stripe: "/create-payment-intent",
  sale: "/my-sales",
  balance: "/balances/user",
  myFriends: "/users/friends/my-friends",
  friendSuggestions: "/users/friends/suggestions",
  products: "/products",
  messages: "/messages/send-to",
  createDiscu: "/messages/create-discussion",
  checkFriend: "/users/friends/verify-friend",
  transfert: "/transfers",
  executePaymentPaypal: "/orders/paypal/execute-payment/",
  translate: "/translate",
  notifications: "/notifications",
};

export const attributesAll = {
  following: "Following",
  followers: "Followers",
  receiving: "Receiving",
  waiting: "Waiting",
  nothing: "Nothing",
};

export const moderateurId = "643eab1e367e10ae448d88b3";
