import React from 'react';
import { Link } from 'react-router-dom';
import mastercard from '../../../assets/payment/mastercard-226450.png';
import visa from '../../../assets/payment/visaLogo.jpeg';
import cb from '../../../assets/payment/cb-card.jpeg';
import facebook from '../../../assets/social-media/facebook.png';
import instagram from '../../../assets/social-media/instagram.png';
import twitter from '../../../assets/social-media/twitter.png';
import ModalCGU from '../../Modal/ModalCGU';

export default function StickyFooter(props) {
	const cards = [visa, mastercard, cb];
	const social_media = [
		{ img: facebook, url: 'https://www.facebook.com/profile.php?id=100085394228305' },
		{ img: instagram, url: 'https://www.instagram.com/my_beedoo/?hl=fr' },
		{ img: twitter, url: 'https://twitter.com/My_bee_doo' },
	];

	const styleImg = {
		position: 'absolute',
		width: '20px',
		height: '20px',
		top: '8px',
		left: '23px',
	};

	const styleDiv = {
		width: '35px',
		height: '35px',
	};

	return (
		<div className="d-flex flex-column footer sticky-footer my-3">
			{/* <Link to="/CGU">TOS</Link> */}
			<div>
				<ModalCGU />
			</div>
			{/* <Link to="/NTF">NTF</Link> */}
			<Link to="/messages?ui=admin">Contact us</Link>
			<Link to="/">App</Link>
			<div className="mb-2">
				<div className="text-muted">Join the community</div>
				<div className="list-card-payment row">
					{social_media.map((items, index) => (
						<div className="col-3" key={index}>
							<a href={items.url} target='_blank'>
								<div
									className="customYellow rounded-pill text-center pointer-cursor"
									style={styleDiv}>
									<img src={items.img} alt="" style={styleImg} key={index} />
								</div>
							</a>
						</div>
					))}
				</div>
			</div>

			{/* <div className="">
				<div className="text-muted py-1">Secure payment</div>
				<div className="list-card-payment">
					{cards.map((items, index) => (
						<img
							src={items}
							alt=""
							width="50px"
							height="35px"
							key={index}
							className="mx-1"
						/>
					))}
				</div>
			</div> */}
			{/* <Link to="/">Nos valeurs</Link>
			<Link to="/">Contactez-nous</Link>
			<Link to="/">Conditions</Link>
			<Link to="/">Politique de confidentialité</Link>
			<Link to="/">Cookies</Link>
			<Link to="/">Informations sur les publicités</Link> */}
			<p className="mt-3">© 2024 MyBeeDoo</p>
		</div>
	);
}
