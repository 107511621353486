import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import user_add from "../../assets/profil/user_add.svg";
import liste_damis from "../../assets/liste_damis.png";
import InfiniteScroll from "react-infinite-scroll-component";
import defaultProfile from "../../assets/profil/utilisateur.png";
import {
  cancelNewRequestFriend,
  checkIfFriend,
  friendRequestRefuse,
  friendRequestValidate,
  getStatusUser,
  sendNewRequestFriend,
} from "../../services/stores/actions/compte";
import Followup from "../Followup/Followup";
import { attributesAll } from "../../res/constants";
import { useSelector } from "react-redux";
import { statusGenerator } from "../../services/stores/actions/services";
import { CancelPresentationTwoTone } from "@material-ui/icons";
import ImageComponent from "../Image/image";

export function ModalDetailsUser(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {props.title && (
        <button
          // className="btn btn-yellow btn-rounded pointer-cursor k-regular-15"
          className="ml-auto btn-rounded btn-sm rounded-pill"
          variant="btn-rounded btn-sm rounded-pill"
          style={{
            fontFamily: "KohinoorRegular",
            border: "solid 1px #F7CC2A",
            fontSize: "15px !important",
          }}
          onClick={handleShow}
        >
          {props.custom && <img src={user_add} alt="" />} {props.title}
        </button>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="row mx-0 justify-content-center">
          <img src={user_add} width={`50px`} height={`50px`} alt="" />
          <p className="text-center k-semibold-20 w-100 pt-2">{props.title}</p>
        </Modal.Header>

        <Modal.Footer>
          <div className="col-12">
            {props.status === "REFUSED" ? null : (
              <button
                className="btn btn-danger btn-rounded k-regular-15 mr-2 btn-block"
                style={{ border: "none" }}
                onClick={() => {
                  if (props.isSender) {
                    props.onClickSender();
                  }
                  if (!props.isSender) {
                    props.onClickRefuse();
                  }
                  handleClose();
                }}
              >
                {props.button}
              </button>
            )}

            {!props.isSender && props.title !== "Friend" ? (
              <button
                className="btn btn-yellow btn-rounded k-regular-15 mr-2 btn-block"
                style={{ border: "none" }}
                onClick={() => {
                  props.onClickValidate();
                  handleClose();
                }}
              >
                Accept a request
              </button>
            ) : !props.isSender && props.title === "Request refused" ? (
              <button
                className="btn btn-yellow btn-rounded k-regular-15 mr-2 btn-block"
                style={{ border: "none" }}
                onClick={() => {
                  props.onClickValidate();
                  handleClose();
                }}
              >
                Send request
              </button>
            ) : null}
            <button
              className="btn btn-light btn-rounded k-regular-15 btn-block"
              style={{ border: "none" }}
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export function ModalDetailsUserFollowing(props) {
  const [show, setShow] = useState(false);
  const currentUser = useSelector(
    (state) => state.compteReducers.userConnected
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [userCurrent, setUserCurrent] = useState([]);
  const [usersFollowing, setUsersFollowing] = useState([]);
  const [usersNothing, setUsersNothing] = useState([]);

  const filterListUsers = () => {
    const listCurrentUserTemp = [];
    const listUsersNothingTemp = [];
    const listUsersFollowingTemp = [];
    props?.tabs.forEach(async (user) => {
      const userId = props.isFollowing
        ? user?.user_full.id
        : user?.sender_full.id;
      if (currentUser.id !== userId) {
        const request = await checkIfFriend(userId);
        const status = statusGenerator({
          requestDetails: request,
          userId,
        });
        const newUser = { ...user, status: status, request: request };
        if (status.some((itemStatus) => itemStatus === attributesAll.nothing)) {
          listUsersNothingTemp.push(newUser);
        } else {
          listUsersFollowingTemp.push(newUser);
        }
      } else {
        const request = await checkIfFriend(props?.userToFind.id);
        const status = statusGenerator({
          requestDetails: request,
          userId: props?.userToFind.id,
        });
        const newUser = { ...user, status: status, request: request };
        listCurrentUserTemp.push(newUser);
      }
      setUsersNothing(() => [...listUsersNothingTemp]);
      setUsersFollowing(() => [...listUsersFollowingTemp]);
      setUserCurrent(() => [...listCurrentUserTemp]);
    });
  };

  const cancelFriendRequest = (request) => {
    cancelNewRequestFriend(request.id).then(() => {
      filterListUsers();
    });
  };

  const sendFriendRequest = (e, user) => {
    e.preventDefault();
    sendNewRequestFriend(user.id).then(() => {
      filterListUsers();
    });
  };

  const removeFollowers = (e, request, status) => {
    e.preventDefault();
    friendRequestRefuse(request[0].id).then(() => {
      cancelFriendRequest(request[1]);
      filterListUsers();
    });
  };

  useEffect(() => {
    filterListUsers();
  }, [show]);

  const ButtonStatus = ({ user, request, status, isCurrent }) => {
    if (status.some((itemStatus) => itemStatus === attributesAll.nothing)) {
      return (
        <button
          className="btn btn-yellow btn-rounded k-regular-12"
          onClick={(e) => sendFriendRequest(e, user)}
        >
          <img src={user_add} alt="" /> Follow
        </button>
      );
    }
    if (
      status.some((itemStatus) => itemStatus === attributesAll.following) &&
      currentUser.id !== user.id
    ) {
      return (
        <button
          className="btn btn-gray btn-rounded k-regular-12"
          onClick={(e) => removeFollowers(e, request, status)}
        >
          Remove
        </button>
      );
    }
    if (
      (status.some((itemStatus) => itemStatus === attributesAll.followers) ||
        status.some((itemStatus) => itemStatus === attributesAll.waiting)) &&
      !isCurrent
    ) {
      return (
        <button className="btn btn-yellow btn-rounded k-regular-12">
          Following
        </button>
      );
    }
    return <></>;
  };

  const itemUser = ({ following, value, index }) => {
    return (
      <div
        className="item d-flex align-items-center mt-2 pointer-cursor"
        key={value.id}
      >
        <div className="image mr-2">
          <ImageComponent
            isRounded={true}
            widthParent={"32px"}
            heightParent={"32px"}
            src={
              props.isFollowing
                ? following?.user_full.profil
                  ? following?.user_full.profil
                  : defaultProfile
                : following?.sender_full.profil
                  ? following?.sender_full.profil
                  : defaultProfile
            }
            alt="..."
            className="img-fluid mr-2"
          />
        </div>
        <div className="mr-auto d-flex pointer-cursor">
          <span
            className="k-semibold-15 d-flex followback"
            onClick={(e) => {
              if (props.isFollowing) {
                props.props.props.history.push({
                  pathname: `/details-user/${following.user_full.pseudo}`,
                  id: following.user_full.id,
                });
              } else {
                props.props.props.history.push({
                  pathname: `/details-user/${following.sender_full.pseudo}`,
                  id: following.user_full.id,
                });
              }
            }}
          >
            {props.isFollowing
              ? `${following.user_full.pseudo.substring(0, 30)}`
              : `${following.sender_full.pseudo.substring(0, 30)}`}
          </span>
          {following.status.some(
            (itemStatus) => itemStatus === attributesAll.following
          ) &&
            !following.status.some(
              (itemStatus) => itemStatus === attributesAll.waiting
            ) &&
            currentUser.id !==
              (props?.isFollowing
                ? following.user_full.id
                : following.sender_full.id) && (
              <button
                className="pointer-cursor bg-transparent border-0 d-flex align-items-start"
                style={{
                  fontSize: "12px",
                  color: "#3c82e4",
                  fontWeight: "0",
                  height: "100%",
                  letterSpacing: "0.5px",
                }}
                onClick={(e) =>
                  sendFriendRequest(e, {
                    id: props?.isFollowing ? following.user : following.sender,
                  })
                }
              >
                Follow back
              </button>
            )}
        </div>

        {following.is_friends && (
          <Followup
            isConnected={this.props.isConnected}
            id={following.id}
            setStateOfParent={props.setStateOfParent}
            isFriend={following.is_friends}
            key={index}
          ></Followup>
        )}
        <ButtonStatus
          user={props.isFollowing ? following.user_full : following.sender_full}
          status={following.status}
          request={following.request}
          isCurrent={
            (props.isFollowing
              ? following.user_full.id
              : following.sender_full.id) === currentUser.id
          }
        />
      </div>
    );
  };

  const ListUserComponent = ({ listUser }) => {
    return listUser.map((following, index) => {
      const value = [];
      return itemUser({ following, value, index });
    });
  };

  return (
    <>
      {props.title && (
        <span className="pointer-cursor k-semibold-13" onClick={handleShow}>
          {`${props.title} `}
          <span className="k-regular-13 label">{props.label}</span>
        </span>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        size={`${props.isMobile ? "sm" : "md"}`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className=" k-semibold-20" closeButton>
          {props.label}
        </Modal.Header>
        <Modal.Body>
          <div className="col">
            <div className="row mx-0 justify-content-center">
              <img src={liste_damis} width={`50px`} height={`50px`} alt="" />
            </div>
            <div className="col mx-0 w-100">
              <InfiniteScroll
                dataLength={props.tabsLength}
                hasMore={true}
                height={300}
              >
                <ListUserComponent listUser={userCurrent} />
                <ListUserComponent listUser={usersFollowing} />
                <ListUserComponent listUser={usersNothing} />
              </InfiniteScroll>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
