import { useEffect } from "react";
import CompressedImageComponent from "../CompressedImage/CompressedImage";

const ImageComponent = ({
  src,
  alt,
  widthParent,
  heightParent,
  isRounded,
  className,
  ...props
}) => {
  useEffect(() => {
    console.log("isRounded : ", props["className"]);
  });

  return props["isFullSize"] ? (
    <img
      alt={alt}
      src={src}
      {...props}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    />
  ) : isRounded ? (
    <div
      style={{
        width: widthParent ? widthParent : "44px",
        height: heightParent ? heightParent : "44px",
        overflow: "hidden",
        borderRadius: "50%",
        cover: "auto",
      }}
    >
      <CompressedImageComponent
        imageUrl={src}
        alt={alt}
        className={`${className} img-fluid img_part`}
        {...props}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      />
    </div>
  ) : (
    <CompressedImageComponent
      imageUrl={src}
      alt={alt}
      className={className}
      {...props}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    />
  );
};

export default ImageComponent;
