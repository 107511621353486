import React, { useRef, useState, useEffect, useCallback } from "react";
import Webcam from "react-webcam";
import { ImCross } from "react-icons/im";
import { MdCameraswitch } from "react-icons/md";
import { BsCameraVideoFill } from "react-icons/bs";
import { FaCamera, FaPlay, FaStop } from "react-icons/fa";

import { Plugins } from "@capacitor/core";
import "./webcam.css";

const { Permissions } = Plugins;

const CameraComponent = ({ closeCam, saveMedias }) => {
  const [type, setType] = useState("IMAGE");
  const [medias, setMedias] = useState(null);

  const HandlerSwitchToVideoOrPhoto = () => {
    setType((state) => {
      if (state === "IMAGE") {
        return "VIDEO";
      } else {
        return "IMAGE";
      }
    });
  };

  const handlerClose = (event) => {
    if (event.target.classList.contains("custom-webcam")) {
      closeCam();
    }
  };

  const handlerSaveMedia = () => {
    if (medias) {
      saveMedias(medias, type);
      closeCam();
    }
  };

  return (
    <div className="custom-webcam" onClick={handlerClose}>
      <div className="webcam-content">
        <ImCross
          size={15}
          color={"white"}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: "5",
            cursor: "pointer",
            margin: "10px",
          }}
          onClick={function (e) {
            closeCam();
            setMedias(null);
          }}
        />
        {medias && (
          <button
            className="btn btn-primary m-2"
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              zIndex: "5",
              cursor: "pointer",
            }}
            onClick={() => {
              handlerSaveMedia();
            }}
          >
            Done
          </button>
        )}
        {type === "IMAGE" ? (
          <ImageCameraComponent
            switchMedia={HandlerSwitchToVideoOrPhoto}
            setMedias={setMedias}
          />
        ) : (
          <VideoCameraComponent
            switchMedia={HandlerSwitchToVideoOrPhoto}
            setMedias={setMedias}
          />
        )}
      </div>
    </div>
  );
};

export default CameraComponent;

const ImageCameraComponent = ({ switchMedia, setMedias }) => {
  const cameraRef = useRef(null);
  const [facingMode, setFacingMode] = useState("user");
  const [pictures, setPictures] = useState([]);
  const [isGrantedCamera, setIsGrantedCamera] = useState(false);

  const handlerSwitchCamera = () => {
    setFacingMode((state) => (state === "user" ? "environment" : "user"));
  };

  const handlerTakePicture = () => {
    if (cameraRef.current) {
      const imageSrc = cameraRef.current.getScreenshot();
      setPictures((state) => {
        setMedias([...state, imageSrc]);
        return [...state, imageSrc];
      });
    }
  };

  const handlerDeletePicture = (index) => {
    if (index !== pictures.length - 1)
      setPictures((state) => {
        const data = [...state.slice(0, index), ...state.slice(index + 1)];
        setMedias(data);
        return data;
      });
    else
      setPictures((state) => {
        const data = state.slice(0, -1);
        setMedias(data);
        return data;
      });
  };

  const handlerPermissionCamera = async () => {
    const status = await Permissions.requestPermissions({ name: "camera" });
    if (status.granted) {
      setIsGrantedCamera(true);
    } else {
      setIsGrantedCamera(false);
    }
  };

  useEffect(() => {
    handlerPermissionCamera();
    setMedias(null);
  }, []);

  return (
    <>
      <Webcam
        className="webcam"
        ref={cameraRef}
        videoConstraints={{
          facingMode,
        }}
        onUserMediaError={(error) => {
          alert(error);
        }}
      />
      <div className="webcam-list">
        <div className="webcam-list-content">
          {pictures.map((picture, index) => {
            return (
              <div className="webcam-item">
                <img className="media" src={picture} alt="screen" />
                <div
                  className="delete-media"
                  onClick={() => {
                    handlerDeletePicture(index);
                  }}
                >
                  <ImCross color={"white"} size={15} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="webcam-options">
        <MdCameraswitch
          className="click"
          size={30}
          color="white"
          onClick={handlerSwitchCamera}
        />
        <button
          className="capture click d-flex justify-content-center align-items-center p-2"
          onClick={handlerTakePicture}
        ></button>
        <BsCameraVideoFill
          className="click"
          size={30}
          color="white"
          onClick={(e) => {
            e.preventDefault();
            switchMedia();
          }}
        />
      </div>
    </>
  );
};

const VideoCameraComponent = ({ switchMedia, setMedias }) => {
  const cameraRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunk, setRecordedChunk] = useState([]);
  const [facingMode, setFacingMode] = useState("user");
  const [video, setVideo] = useState(null);
  const [timeout, setTimeoutState] = useState(null);
  const [isGrantedCamera, setIsGrantedCamera] = useState(false);
  const mediaRecorderRef = useRef(null);

  const handlerSwitchCamera = () => {
    setFacingMode((state) => (state === "user" ? "environment" : "user"));
  };

  const handlerDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunk((state) => {
          handlerSaveData([data]);
          return [data];
        });
      }
    },
    [setRecordedChunk]
  );

  const handlerStartRecordVideo = useCallback(
    (event) => {
      event.preventDefault();
      setTimeoutState(
        setTimeout(() => {
          setIsRecording(false);
          mediaRecorderRef.current.stop();
        }, 15000)
      );
      mediaRecorderRef.current = new MediaRecorder(cameraRef.current.stream, {
        mimeType: "video/mp4",
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handlerDataAvailable
      );
      setIsRecording(true);
      mediaRecorderRef.current.start();
      console.log("Recorded video start");
    },
    [cameraRef, isRecording, mediaRecorderRef, handlerDataAvailable]
  );

  const handlerStopRecordVideo = useCallback(
    async (event) => {
      event.preventDefault();
      clearTimeout(timeout);
      setIsRecording(false);
      mediaRecorderRef.current.stop();
      console.log("Recorded video stop");
    },
    [setIsRecording, mediaRecorderRef]
  );

  const handlerSaveData = useCallback(
    (data) => {
      if (data.length) {
        const blob = new Blob(data, {
          type: "video/mp4",
        });
        const url = URL.createObjectURL(blob);
        console.log("test", url);
        setMedias(blob);
        setVideo(url);
      }
    },
    [isRecording]
  );

  const handlerDeleteVideo = () => {
    setVideo(null);
    setMedias(null);
    setRecordedChunk([]);
  };

  const handlerPermissionCamera = async () => {
    const status = await Permissions.requestPermissions({ name: "camera" });
    if (status.granted) {
      setIsGrantedCamera(true);
    } else {
      setIsGrantedCamera(false);
    }
  };

  useEffect(() => {
    handlerPermissionCamera();
    setMedias(null);
  }, []);

  return (
    <>
      <Webcam
        className="webcam"
        audio={true}
        ref={cameraRef}
        videoConstraints={{
          facingMode,
        }}
        onUserMediaError={(error) => {
          alert(error);
        }}
      />
      <div className="webcam-list">
        <div className="webcam-list-content d-flex">
          {video && (
            <div className="webcam-item">
              <video
                className="media"
                src={video}
                loop={true}
                muted
                autoPlay="true"
                playsInline
                controls
                controlsList="nodownload"
              />
              <div
                className="delete-media"
                onClick={() => {
                  handlerDeleteVideo();
                }}
              >
                <ImCross color={"white"} size={15} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="webcam-options">
        <MdCameraswitch
          className="click"
          size={30}
          color="white"
          onClick={handlerSwitchCamera}
        />
        <button
          className="capture click d-flex justify-content-center align-items-center p-2"
          onClick={(event) => {
            event.preventDefault();
            if (isRecording) {
              handlerStopRecordVideo(event);
            } else {
              handlerStartRecordVideo(event);
            }
          }}
        >
          {!isRecording && (
            <FaPlay className="ml-1" color="red" size={"auto"} />
          )}
          {isRecording && <FaStop color="red" size={"auto"} />}
        </button>
        <FaCamera
          className="click"
          size={30}
          color={"white"}
          onClick={(e) => {
            e.preventDefault();
            switchMedia();
          }}
        />
      </div>
    </>
  );
};
